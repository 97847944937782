import React, { useState, useEffect, useContext } from "react";
import Footer from "../../../components/Footer";
import Header from "../../../components/Header";
import ZodiacDD from "../../../components/ZodiacDD";
import banner from "../../../staticAssets/Horoscope Bg.png";
import logo from "../../../staticAssets/Leo.png";
import axios from "axios";
import { navigate } from "gatsby";
import { AuthContext } from "../../../context/Auth";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "../../../context/Analytics";

function Index({location}) {
  const [blogs, setblogs] = useState([]);
const { gtmPageViewEvent } = useContext(AnalyticsContext) || {
    gtmPageViewEvent: () => {},
  };
  const { user } = useContext(AuthContext) || {
    user: {},
  };
  useEffect(() => {
    const fn = async () => {
      let res = await axios.get(
        "https://www.bodhi.app/blog/wp-json/wp/v2/posts"
      );

      if (res.status == 200) {
        setblogs([...res.data]);
      }
    };
    gtmPageViewEvent(window.location.pathname, window.location.host);
fn();
  }, []);
  return (
    <div className="h-screen flex flex-col block hide-scroll-bar scrollbar-hide worksans w-screen overflow-x-clip">
      <Header />
      <Helmet>
        <link
          rel="canonical"
          href={"https://www.bodhi.app"+location?.pathname}
        />
      </Helmet>
      <div className="flex-1 overflow-y-auto flex flex-col">
        <main className="grow hide-scroll-bar scrollbar-hide">
          <div className=" w-full relative bg-[#FFEEEF] overflow-hidden">
            <img className="h-32 md:h-auto w-full object-fill" src={banner} />
            <div className="absolute top-0 w-full h-full flex justify-center items-center">
              <h1 className="text-center text-2xl md:text-4xl font-semibold text-white">
                Leo Traits
              </h1>
            </div>
          </div>
          <div className="container mx-auto flex flex-col">
            <div className="flex flex-wrap my-2 ml-2">
              <a
                href="https://www.bodhi.app/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Bodhi</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Zodiac Signs</h4>
              </a>
              <h4 className="mx-2 text-sm">/</h4>
              <a
                href="https://www.bodhi.app/zodiac-signs/leo"
                className="cursor-pointer"
              >
                <h4 className="text-orange-600 text-sm">Leo</h4>
              </a>
              
              <h4 className="mx-2 text-sm">/</h4>
              <h4 className="text-sm">Leo Traits</h4>
            </div>
            <div className="flex mt-10 items-center">
              <div>
                <img className="h-24 md:h-48" src={logo} />
              </div>
              <div className="flex-1 flex flex-col md:flex-row">
                <div className=" flex-1 flex flex-col">

                <h1 className="font-semibold text-xl md:text-4xl ml-4">Leo Traits</h1>
                <h2 className="text-sm md:text-base ml-4">July 23 - August 22</h2>
                </div>
                <div className="">
                <ZodiacDD name={"Change Sunsign"} />
              </div>
              </div>
              
            </div>
            <div className="grid grid-cols-3 md:grid-cols-4 gap-2 md:gap-8 mt-10 text-[0.8rem] md:text-base mx-1">
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2 py-1" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-woman");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Woman</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>

              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-health");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Health</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-career");
                  }}>
                <div className="flex justify-between items-center">
                  <h5 className="font-semibold text-black">Leo Career</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-love");
                  }}>
                  <h5 className="font-semibold text-black">Leo Love</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-nature");
                  }}>
                  <h5 className="font-semibold text-black">Leo Nature</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-man");
                  }}>
                  <h5 className="font-semibold text-black">Leo Man</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-relationship");
                  }}>
                  <h5 className="font-semibold text-black">Leo Relationship</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-traits");
                  }}>
                  <h5 className="font-semibold text-black">Leo Traits</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
              <button className="bg-[#FFFAF5] rounded-xl border border-[#F9D9B8] w-full md:h-12 px-2">
                <div className="flex justify-between items-center" onClick={() => {
                    navigate("/zodiac-signs/leo/leo-facts");
                  }}>
                  <h5 className="font-semibold text-black">Leo Facts</h5>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </div>
              </button>
            </div>
            <span className="my-4 mx-1 md:mx-0">
            <p className="playfair text-black text-xl md:text-3xl">Leo Traits</p>
            <br></br>
In the sign of Leo, the Sun brings this energy to its height, heightening their charisma and self-confidence to the point that they can be highly overbearing and self-centered. This can make them appear egotistical and domineering. Still, it is simply their nature to be the leader of the social world. They can be very charming and persuasive, so they are natural leaders. Still, they can also be extremely stubborn and set in their ways.
 <br/><br/>
 
So to give you some insight into what it means to be born under the Leo sign, we'll be revealing positive and negative Leo traits and characteristics.
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Let's look at some of the positive and negative traits of Leo</p> <br/>
<p className="font-bold text-orange-500 inline-block mr-2">Positive Traits:</p> Caring and lives a good life, optimistic, natural leader, kind and protective, loyal, honest, enthusiastic, and Attention seeking<br/><br/>
<p className="font-bold text-orange-500 inline-block mr-2">Negative Traits:</p> Arrogance, inflexibility, laziness, dogmatic and prideful, jealous and competitive
 <br/><br/>
<p className="playfair text-black text-xl md:text-3xl">Leo Positive Traits</p><br/>
<p className="playfair text-black text-xl md:text-3xl">Caring and living a good life</p><br/>
Your friends and family are your life. When you are down, they are the ones who will come to visit and bring you a meal. They are the ones who will drop everything to help you when you are in trouble. They are the ones you will go to when you need someone to talk to.<br/><br/>
Your friendships are life-changing. When you're in a wrong place, the people in your circle will always have your back. You will go to great lengths for them, and they will do the same for you. You can have a massive group of friends, but if you have a few close friends like brothers and sisters, you are one of the lucky ones.
 <br/><br/>
You have a  big circle of friends and acquaintances. You will care for and nurture all of them and are very generous to them. At your best, you are courage<br/><br/>
ous and strong. You have a unique, protective aura around you that gives you power.<br/><br/>
You are the kind of person who loves to be free. You don't like it when someone tells you what to do or how to do it. You want to live your life the way you want to when you want to. You don't like it when someone tells you what to do or how to do it.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Optimistic</p><br/>
Leo is a born leader with a generous spirit. No matter the situation, you find a way to turn it into an opportunity for growth. Other people seem to gravitate toward you for advice and help, and you are never shy to provide it. Your optimistic nature often leads you to find the silver lining in even the darkest of clouds, and you are able to bring a smile to others' faces with your infectious happiness.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">A natural leader and popular</p><br/>
You are a natural leader, and you're driven to be at the top of your field. You have a strong desire to be the best, and you're not satisfied with the second-best. You also have a very blunt and sometimes annoying way of telling people when they're doing something wrong. Your actions often inspire others to follow you, and they know they can count on you to be there for them in a crisis. You love to become famous and popular among your friends and family.  
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Kind and protective</p><br/>
You have the air of a protector; you feel responsible for everybody and everything around you. You are constantly looking out for the needs of your close and loved ones, and you tend to be very kind and generous creatures. You have a big heart and are very giving, but you can be a bit too trusting at times, making you a bit of a target for those who would like to take advantage of your kindness. But you have a strong sense of right and wrong, and you are never afraid to speak up when you see somebody doing something wrong.
 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Loyal</p><br/>
You are very loyal to your friends, close ones, and acquaintances. You tend to be very reliable to people who have treated you well, and you will defend them against anyone who tries to bring them down. This loyalty is so deeply ingrained in you that it is sometimes difficult to see when someone is mistreating you because you feel responsible for them and want to bring them happiness. This loyalty makes you a great friend, but it can also make you feel trapped because you are so invested in the people around you - and sometimes, you need to be able to look out for yourself.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Honest</p><br/>
You like to be straightforward and honest with people. You hate being lied to, and you're not afraid to tell someone if you think they're being deceptive. You give direct feedback to people when they ask for it, and you expect the same in return. You're not the most sensitive person, so you don't get offended very easily.
 <br/><br/>
You are straightforward and will never speak white lies just to impress others. Your honesty often comes off as excessive bluntness.
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Vivacious and Attention seeking</p><br/>
You are naturally funny, charismatic, and highly talented. You are great at being the center of attention and being the party's life. You love to be the center of attention, and you get a lot of attention. You are highly confident and love being the center of attention.
You are a natural-born performer and need to be in the limelight. You thrive on being the center of attention, and you love to be the show's star. You have a very high opinion of yourself, and you often get what you want. You are a people pleaser, and you are good at making people feel good. You never love to share your stage with anyone.
 
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Leo Negative Traits</p><br/>
 <p className="playfair text-black text-xl md:text-3xl">Arrogance</p><br/>
At some point, You have a very self-confident and arrogant personality. You tend to be dominant in social situations and can come across as arrogant. You feel that the world is yours, and you want everyone to recognize your greatness. You don't like to be ignored, and you sometimes forget to listen when others are speaking.
 <br/><br/>
You think you are always right, and you totend not to listen to others. It would be best if you learned to balance your need for control and leadership with being more patient and understanding. 
 
 <br/><br/>
 
 <p className="playfair text-black text-xl md:text-3xl">Inflexibility</p><br/>
You are inflexible sometimes. You don't like to change your mind or go back on your word, even when it is in your best interest or the best interest of others. This can make you seem stubborn or pig-headed, even though you have good intentions. It can be hard to understand because you don't mean to be inflexible.
 
 
<br/><br/> 
<p className="playfair text-black text-xl md:text-3xl">Laziness</p><br/>
You are full of ambition and enthusiasm, but you might also be lazy. You will take the easy way out for given opportunities and situations. You will be especially lazy when the position requires that you work hard. This tendency to be lazy can make you appear indecisive, and others will use this against you when they need a decision.
 <br/><br/>
 <p className="playfair text-black text-xl md:text-3xl">Dogmatic and Prideful</p><br/>
If you've ever seen a lion pride in the wild, you've seen the king of the beasts. He's the biggest, the strongest, the best. He's the leader of the group, and everyone knows it. He roams around with his head high, proud of his strength and prowess, and he makes sure everyone knows it.
 
You find difficulty in self-reflection and will never admit to your mistakes. You find it hard to accept other suggestions even if they are right.
 <br/><br/>
Jealous and Competitive
You're always the best, no matter what. You don't enjoy competition, but when someone outshines you, you feel a sense of jealousy. You try to be better, brighter, and more beautiful than everyone else. This is your natural state.
 <br/><br/>
You're the kind of person who always strives to be the best. You rarely accept second best, and when you do, you work even harder to get back on top. You don't like it when someone outshines you, either positively-- in terms of achievements, grades, or talent-- or negatively-- in terms of looks, popularity, or skill. You tend to get jealous and competitive.
  <br/><br/>
 
Analyzing The position of the planets when you are born is one of the most critical factors in your life. It has a significant impact on your personality, relationships, and destiny. The position of the planets at the moment you were born reveals your personality traits, sign, and element. Your sign, in particular, will give you a better understanding of your emotions, desires, and hopes.
Get your Free Personalized Janampatri and get the most profound insights into your zodiac sign.
 <br/><br/>
            </span>
          </div>
          <div className="container mx-auto">
            <div className="flex justify-between mb-4 mx-2">
              <h1 className="text-xl md:text-3xl text-black playfair">
                Recent Blogs
              </h1>
              <a
                className="text-sm md:text-md text-orange-500 text-center font-semibold"
                href="https://www.bodhi.app/blog/"
              >
                See More
              </a>
            </div>
            <div className="relative">
                <div className="absolute top-0 right-0 w-0 md:w-36 fixed h-full bg-gradient-to-r from-transparent to-white"></div>
              <div className="flex overflow-x-scroll pb-4 mt-2 hide-scroll-bar scrollbar-hide">
                <div className="flex flex-nowrap ml-2">
                  {blogs.slice(0, 10).map((item, i) => {
                    return (
                      <a href={item.link} key={i} target="_blank">
                        <div className="flex flex-col h-80 w-52 justify-start items-start bg-gray-100 mr-10 mb-10 rounded-lg overflow-hidden hover:shadow-lg">
                          <img
                            className="object-cover w-52 h-52 w-auto bg-gray-100"
                            src={
                              item?.yoast_head_json?.og_image
                                ? item?.yoast_head_json?.og_image[0]?.url
                                : ""
                            }
                          />
                          <span className="mt-2 block mx-2 text-center">
                            {item?.yoast_head_json?.title ?? ""}
                          </span>
                        </div>
                      </a>
                    );
                  })}
                  {/* <div className="flex h-80 w-52 justify-center items-center "></div> */}
                </div>
              </div>
            </div>
          <button
            className="justify-center text-white fixed right-2 bottom-2 rounded-t-2xl but px-4 py-2 flex gap-1 shadow-xl"
            onClick={() => {
              navigate("/consultAstro");
            }}
          >
            {user.name !== undefined?`Consult Top Astrologers Now!`:`Login & get ${
              user?.currencyType == "usd" ? "$ 4" : "₹ 91"
            } in your account!`}
          </button>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default Index;
